<template>
  <section class="tabs-sect">
    <div class="max-w-5xl px-4 mx-auto sm:px-6">
      <div
        class="mt-8 mb-8 text-2xl font-bold text-left call-text md:text-3xl text-blue"
      >
        Disclaimer
      </div>
      <p>
        Although Miskanawah’s Healing Lodge make every effort to ensure that the
        material in this site is formatted and professionally address the
        problem, no one program can guarantee to prevent youth suicide. The
        Healing Lodge does not guarantee the accuracy, quality, suitability or
        reliability of any information on this website.
      </p>
      <p>
        Our program’s goal is to provide you with educational materials that
        will help you better recognize “signs of concern” that an at-risk your
        friend or family may exhibit. Left unaddressed or untreated, these
        issues that you face, or a young person is struggling with could result
        in suicidal ideation. The content is not intended to be a substitute for
        professional or medical advice. Professionals help should always be
        sought whenever there is a possibility of suicidal ideation.
      </p>
      <div
        class="my-2 font-bold text-left call-text md:text-1xl text-1xl text-blue"
      >
        Legal
      </div>
      <p>
        By using The Miskanawah- Healing Lodge suicide web-app you agree to the
        following:
      </p>
      <p>
        You acknowledge that the web-app contains information, photographs,
        audio and video clips, graphics, and other material (collectively, the
        “Content”) that are protected by copyright, trademark or other
        proprietary rights of Miskanawah or third parties. All Content on the
        web-app is copyrighted as a collective work of Miskanawah pursuant to
        applicable copyright law. Users of the web-app may use the Content only
        for their personal, noncommercial use.
      </p>
      <p>
        You may not modify, publish, transmit, transfer or sell, reproduce,
        create derivative works from, distribute, perform, display, or in any
        way exploit any of the Content, in whole or in part, unless you have
        secured prior, expressed written permission to do as the result of
        personally contacting Miskanawah. Nothing on this web-app shall be
        construed as permission for use other than as outlined above.
      </p>
      <p>
        Miskanawah-healing Lodge web-app may contain links to sites on the
        Internet which are owned and operated by third parties (“External
        Sites”). You acknowledge that Miskanawah is not responsible for the
        availability of, or the content located on or through, any External
        Site. You should contact the site administrator or Webmaster for those
        External Sites if you have any concerns regarding such links or the
        content located on such External Sites.
      </p>
      <p class="mt-6 mb-8">
        If at any time the terms and conditions of this Agreement are no longer
        acceptable to you, you should immediately cease all use of the website.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Disclaimer",
};
</script>

<style scoped></style>
